import React,{useState} from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { useAppConfig } from '../../../config';
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo';
import StructuredData from '../../../components/structured-data'
import SfForm from '../../../components/salesforce/Form';
import SfRecaptcha from '../../../components/salesforce/Recaptcha';

const NAME_KEY = 'name';
const NAME_LABEL = 'Your Full Legal Name:';
const EMAIL_KEY = 'email';
const EMAIL_LABEL = 'Your Contact Email Address:';
const PHONE_KEY = 'phone';
const PHONE_LABEL = 'Your Contact Phone Number:';
const ADDRESS_KEY = '00N3a00000Cm1fF';
const ADDRESS_LABEL = 'Your Full Address:';
const MATERIAL_KEY = '00N3a00000Cm1fU';
const MATERIAL_LABEL = `
A description of where the material that you claim infringes
your copyright is located on the site:
`.replace(/\n/g, ' ').trim();
const COPYRIGHT_KEY = '00N3a00000Cm1fP';
const COPYRIGHT_LABEL = `
A description of the copyrighted work that you claim has been
infringed:
`.replace(/\n/g, ' ').trim();
const ASSERTION_KEY = '00N3a00000Cm1fZ';
const ASSERTION_LABEL = `
A statement by you that you have good faith belief that the
disputed use is not authorized by the copyright owner, its
agent, or the law:
`.replace(/\n/g, ' ').trim();
const STATEMENT_KEY = '00N3a00000Cm1fe';
const STATEMENT_LABEL = `
A statement by you, made under penalty of perjury, that the
above information in your notice is accurate, and that you are
the copyright owner or legally authorized to act on behalf of
the copyright owner:
`.replace(/\n/g, ' ').trim();
const SIGNATURE_KEY = '00N3a00000Cm1fK';
const SIGNATURE_LABEL = `
Please submit your full legal name as a form of electronic
signature in this box:
`.replace(/\n/g, ' ').trim();
const DESCRIPTION_KEY = 'description';
const DESCRIPTION_LABEL = `
Other details or information you would like to provide:
`.replace(/\n/g, ' ').trim();

function DmcaPage({ data }) {
  const { wpPage } = data

  const {sfForms} = useAppConfig();
  const {servlet, orgId, recordTypes} = sfForms;
  const recordType = recordTypes.dmca;

  const [materialState, setMaterialState] = useState('');
  const [copyrightState, setCopyrightState] = useState('');
  const [assertionState, setAssertionState] = useState('');
  const [statementState, setStatementState] = useState('');
  const [descriptionState, setDescriptionState] = useState('');

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 lg:py-20 relative">
        <div className="container relative z-30 text-white">
          <h1 className="text-white mb-2 text-3xl lg:text-4xl">
            Copyright Infringement Policy
          </h1>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `#2e3c4b`,
          }}
        ></div>
      </div>

      <div className="py-10 container">
        <h2 className="text-2xl lg:text-3xl mb-2">
          Notice And Procedure For Making Claims Of Copyright Infringement
        </h2>
        <p className="mb-4">
          Customers of Liquid Web or one or more of its family of brands
          including Nexcess and iThemes are required to respect the legal
          protection provided by copyright law. If you (“Complainant”) believe
          that your work has been copied in a way that constitutes copyright
          infringement by a customer receiving web hosting services from Liquid
          Web, please provide to the LiquidWeb.com copyright agent the
          information listed below. To be considered effective pursuant to the
          Digital Millennium Copyright Act (“DMCA”), 17 U.S.C. § 512(c)(3), your
          notification must include the requested information below. This
          procedure is exclusively for notifying LiquidWeb.com that your
          copyrighted material has been infringed:
        </p>
        <ul className="list-disc m-4 pl-10">
          <li>
            An electronic or physical signature of the person legally authorized
            to act on behalf of the owner of the copyright interest.
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed.
          </li>
          <li>
            A description of where the material that you claim infringes your
            copyright is located on the site.
          </li>
          <li>
            Your name, mailing address, telephone number and e-mail address.
          </li>
          <li>
            A statement by you that you have good faith belief that the disputed
            use is not authorized by the copyright owner, its agent, or the law.
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the above
            information in your notice is accurate, and that you are the
            copyright owner or legally authorized to act on behalf of the
            copyright owner.
          </li>
        </ul>
        <h2 className="text-2xl lg:text-3xl mb-2">
          Designation Of Agent To Receive Notification Of Claimed Infringement
        </h2>
        <p className="mb-4">
          A notification of claimed copyright infringement must be provided in
          writing to:
        </p>
        <p
          className="mb-4 border border-solid border-gray-600 rounded-sm p-4"
          style={{ backgroundColor: `#f5f5f5`, color: `#4f4f4f` }}
        >
          Copyright Agent <br />
          <br />
          LiquidWeb.com
          <br /> 2703 Ena Drive <br />
          Lansing, Michigan 48917
          <br />
          <br />
          phone: 517-322-0434
          <br /> fax: 517-322-0493
          <br /> e-mail: DMCA@liquidweb.com
        </p>
        <p className="mb-4">
          Upon receipt of notification of claimed copyright infringement, Liquid
          Web will follow the procedures outlined in Title II of the Digital
          Millennium Copyright Act (17 USC § 512).
        </p>
        <h2 className="text-2xl lg:text-3xl mb-2">
          Notice And Take-Down Procedures
        </h2>
        <p className="mb-4">
          If Liquid Web is notified of a claim of copyright
          infringement, or otherwise becomes aware of facts and circumstances
          from which infringement is apparent, it will respond expeditiously by
          removing, or disabling access to, the material that is potentially
          infringing.
        </p>
        <h2 className="text-2xl lg:text-3xl mb-2">Counter Notification</h2>
        <p className="mb-4">
          If you received a DMCA notification and believe, upon good faith, that
          the content was removed or disabled in error, you may provide a
          counter-notice by notifying us in writing at the designated agent
          address above, or by emailing DMCA@liquidweb.com, and providing the
          following information:
        </p>
        <ol className="list-decimal m-4 pl-10">
          <li>A physical or electronic signature (type your full name).</li>
          <li>
            Identification of the material that has been removed or to which
            access has been disabled and the location at which the material
            appeared before it was removed or access to it was disabled.
          </li>
          <li>
            A statement under penalty of perjury that you have a good faith
            belief that the material was removed or disabled as a result of
            mistake or misidentification of the material to be removed or
            disabled.
          </li>
          <li>
            Your name, mailing address, and telephone number, and a statement
            that you consent to the jurisdiction of the federal district court
            for the judicial district in which your address is located, or if
            your address is outside of the U.S., for any judicial district in
            which Liquid Web may be found, and that you will accept service of
            process from the Complainant who provided the copyright notification
            or an agent of such Complainant.
          </li>
        </ol>
        <p className="mb-4">
          Upon receipt of a counter-notice, Liquid Web shall promptly forward a
          copy to the Complainant and inform Complainant that it will replace
          the removed material or cease disabling access to it in ten (10)
          business days. Liquid Web will replace the removed material and cease
          disabling access to it in not less than ten (10) business days and no
          more than fourteen (14), business days following receipt of the
          counter-notice unless Liquid Web first receives notice from
          Complainant that it has filed an action seeking a court order to
          restrain the Liquid Web customer from engaging in infringing activity
          relating to the material on Liquid Web’s web hosting site.
        </p>
        <p className="mb-4">
          Please note and understand before submitting a counter-notice: Under
          Section 512(f) of the DMCA, any person who knowingly materially
          misrepresents that material or activity was removed or disabled by
          mistake or misidentification may be subject to liability.
        </p>
        <h2 className="text-2xl lg:text-3xl mb-2">Repeat Infringers</h2>
        <p className="mb-4">
          Under appropriate circumstances, Liquid Web may, in its discretion,
          terminate the accounts of customers who are repeat infringers.
        </p>
        <h2 className="text-2xl lg:text-3xl mb-2">
          Accommodation Of Standard Technical Measures
        </h2>
        <p className="mb-4">
          It is Liquid Web’s policy to accommodate and not interfere with
          standard technical measures, i.e., technical measures that are used by
          copyright owners to identify or protect copyrighted works, and (1)
          have been developed pursuant to a broad consensus of copyright owners
          and service providers in an open, fair, voluntary, multi-industry
          standards process; (2) are available to any person on reasonable and
          nondiscriminatory terms; and (3) do not impose substantial costs on us
          or place substantial burdens on our systems or networks.
        </p>
      </div>
      <div
        id="dmca-form"
        className="py-10"
        style={{ backgroundColor: `#f4f4f4` }}
      >
        <div className="container">
          <SfForm
            action={servlet}
            method="POST"
          >
            <input type="hidden" name="orgid" value={orgId} />
            <input
              type="hidden"
              name="retURL"
              value="https://www.liquidweb.com/form-success-abuse"
            />
            <input
              type="hidden"
              name="RecordTypeId"
              id="RecordTypeId"
              value={recordType}
            />
            <input
              type="hidden"
              name="subject"
              id="subject"
              maxLength="80"
              size="20"
              value="DMCA Form"
            />
            <label htmlFor={NAME_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {NAME_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                id={NAME_KEY}
                type="text"
                aria-label={NAME_KEY}
                name={NAME_KEY}
                required
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor={EMAIL_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {EMAIL_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                id={EMAIL_KEY}
                type="email"
                aria-label={EMAIL_KEY}
                name={EMAIL_KEY}
                required
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor={PHONE_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {PHONE_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                id={PHONE_KEY}
                type="tel"
                aria-label={PHONE_KEY}
                name={PHONE_KEY}
                required
                maxLength="40"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor={ADDRESS_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {ADDRESS_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                id={ADDRESS_KEY}
                type="text"
                aria-label={ADDRESS_KEY}
                name={ADDRESS_KEY}
                required
                maxLength="100"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor={MATERIAL_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {MATERIAL_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                type="hidden"
                name={MATERIAL_KEY}
                value={`${MATERIAL_LABEL}\n${materialState}`}
              />
              <textarea
                id={MATERIAL_KEY}
                aria-label={MATERIAL_KEY}
                required
                rows="2"
                wrap="soft"
                className="form-input w-full"
                onChange={({target}) => { setMaterialState(target.value); }}
                value={materialState}
              />
            </label>
            <label htmlFor={COPYRIGHT_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {COPYRIGHT_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                type="hidden"
                name={COPYRIGHT_KEY}
                value={`${COPYRIGHT_LABEL}\n${copyrightState}`}
              />
              <textarea
                id={COPYRIGHT_KEY}
                aria-label={COPYRIGHT_KEY}
                required
                rows="2"
                wrap="soft"
                className="form-input w-full"
                onChange={({target}) => { setCopyrightState(target.value); }}
                value={copyrightState}
              />
            </label>
            <label htmlFor={ASSERTION_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {ASSERTION_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                type="hidden"
                name={ASSERTION_KEY}
                value={`${ASSERTION_LABEL}\n${assertionState}`}
              />
              <textarea
                id={ASSERTION_KEY}
                aria-label={ASSERTION_KEY}
                required
                rows="2"
                wrap="soft"
                className="form-input w-full"
                onChange={({target}) => { setAssertionState(target.value); }}
                value={assertionState}
              />
            </label>
            <label htmlFor={STATEMENT_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {STATEMENT_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                type="hidden"
                name={STATEMENT_KEY}
                value={`${STATEMENT_LABEL}\n${statementState}`}
              />
              <textarea
                id={STATEMENT_KEY}
                aria-label={STATEMENT_KEY}
                required
                rows="2"
                wrap="soft"
                className="form-input w-full"
                onChange={({target}) => { setStatementState(target.value); }}
                value={statementState}
              />
            </label>
            <label htmlFor={SIGNATURE_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {SIGNATURE_LABEL}
                <span className="text-red-600">*</span>
              </span>
              <input
                id={SIGNATURE_KEY}
                aria-label={SIGNATURE_KEY}
                type="text"
                name={SIGNATURE_KEY}
                required
                maxLength="100"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor={DESCRIPTION_KEY} className="block w-full mb-5">
              <span className="block font-semibold">
                {DESCRIPTION_LABEL}
              </span>
              <input
                type="hidden"
                name={DESCRIPTION_KEY}
                value={`${DESCRIPTION_LABEL} ${descriptionState}`}
              />
              <textarea
                id={DESCRIPTION_KEY}
                aria-label={DESCRIPTION_KEY}
                className="form-input w-full"
                onChange={({target}) => { setDescriptionState(target.value); }}
                value={descriptionState}
              />
            </label>
            <input type="hidden" id="external" name="external" value="1" />
            <div className="w-1/2">
              <SfRecaptcha Warning={(
                <div className="text-red-600 pt-5 font-semibold">Please verify you are not a robot!</div>
              )} />
              <button
                className="btn btn-primary p-3 w-full font-semibold mt-8"
                type="submit"
              >
                Submit
              </button>
            </div>
          </SfForm>
        </div>
      </div>
    </Layout>
  )
}

DmcaPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export const query = graphql`
  {
    wpPage(slug: { eq: "dmca" }) {
      title
      date
      modified
      uri
      chatDepartment
      seo {
        ...postSeoFields
      }
    }
  }
`

export default DmcaPage
